import React from "react"

import Layout from "../components/Layout"
import Intro from "../components/Intro"
import Feature from "../components/Features"
import Solutions from "../components/Solutions"
import Contact from "../components/Contact"

import SEO from "../components/seo"

import { ThemeProvider } from "styled-components"
import General from "../styles/theme"

const IndexPage = () => (
  <ThemeProvider theme={General}>
    <Layout>
      <SEO title="Home" />
      <Intro />
      <Feature />
      <Solutions/>
      <Contact/>
    </Layout>
  </ThemeProvider>
)

export default IndexPage
